<template>
  <div class="container" id="paymentPlans">
    <div class="row mb-3 mb-lg-5" v-if="courseDetail && courseDetail.Packages">
      <div class="col-lg-3 mb-3 px-1" v-for="(coursePackage, index) in courseDetail.Packages" :key="index">
        <div class="paymentBox" :class="[isCourseExist($decryptedRouteParams.sosId, coursePackage.SOSPaymentPackageId) ? 'selected' : '', courseDetail.EnableTrial ? 'freetrail' : '']">
          <coursePackage :model="coursePackage" />
          <div class="cart-btns">
            <button @click="AddToTrial(coursePackage)" class="btn cart-btn" v-if="courseDetail.EnableTrial">Free Trial</button>
            <div class="btn-loader d-flex justify-content-center mt10" v-if="isServer === 'server'">
              <span class="skeleton-loader"></span>
            </div>
            <div v-else-if="!isCourseExist($decryptedRouteParams.sosId, coursePackage.SOSPaymentPackageId)">
              <button @click="AddToCart(coursePackage)" class="btn secondary-btn">Add To Cart</button>
            </div>
            <div v-else>
              <button class="btn cart-btn gotocart" @click="goToCart">
                <i class="fa fa-check-circle mr-1"></i>
                Go to cart
              </button>
              <div class="changePlan" v-if="coursePackage.PaymentPlans.length > 1" @click="AddToCart(coursePackage)">Change Plan</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { courseDetail as courseDetailStoreKeys, course as courseStoreKeys, checkout as checkoutStoreKeys, cart as cartStoreKeys } from "../store/keys";
import { appConstants } from "../common/constants";
import coursePackage from "./packages.vue";
export default {
  components: {
    coursePackage,
  },
  data() {
    return {
      isServer: process.env.VUE_ENV,
    };
  },
  computed: {
    ...mapGetters(courseDetailStoreKeys.namespace, courseDetailStoreKeys.getters),
    ...mapGetters(cartStoreKeys.namespace, cartStoreKeys.getters),
  },
  mounted() {},
  methods: {
    ...mapActions(cartStoreKeys.namespace, cartStoreKeys.actions),
    async AddToCart(obj) {
      this.$emit("choosePlan", obj, appConstants.enums.PaymentOption.PaymentPlan);
    },
    goToCart() {
      this.$router.push({ name: "cart" }).catch(() => {});
    },
    AddToTrial(coursePackage) {
      this.$emit("choosePlan", coursePackage, appConstants.enums.PaymentOption.Trial);
    },
  },
};
</script>
