<template>
  <div>
    <section class="online-course-details course-details-page">
      <!-- <p class="course-heading-caption" v-if="routeParams.board">
              {{ routeParams.board.replace("-", " ") }} -
              {{ routeParams.group }}
            </p> -->
      <page-header :caption="courseDetail && courseDetail.CourseGroupName" :heading="courseDetail && courseDetail.DisplayName" :desc="courseDetail && courseDetail.ShortDescription" />
      <!-- <loader id="checkoutLoaderId"></loader> -->
      <skeleton-loader id="courseDetailLoader" :count="12" :showEmptyTemplate="true" :defaultLoadingProp="true">
        <template v-slot:default="{}">
          <section class="details-section" :style="{ minHeight: boxHeight }">
            <div class="container">
              <div class="row detail-sec-row">
                <div class="col-lg-7">
                  <div class="details-info">
                    <div v-html="courseDetail && courseDetail.Description"></div>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="">
                    <div v-if="courseDetail" class="white-box sticky-side-box" id="bar-fixed">
                      <div class="sticky-img-cntr" v-if="courseDetail && courseDetail.CourseImage">
                        <img :src="courseDetail.CourseImage" width="100%" height="100%" :alt="courseDetail.DisplayName" />
                      </div>
                      <div class="sticky-img-cntr" v-else>
                        <i class="no-course-image fal fa-user-graduate"></i>
                      </div>
                      <div class="cardContainer" ref="stickyBox">
                        <div class="sticky-side-box-margin">
                          <div class="cart-btns p-0" v-if="courseDetail && courseDetail.IsFree">
                            <button @click="FreeCourse(courseDetail)" class="btn secondary-btn">Enroll</button>
                          </div>
                          <div class="cart-btns p-0" v-if="courseDetail && courseDetail.Packages && courseDetail.Packages.length > 1">
                            <div class="btnHolder">
                              <button @click="scrollToPaymentPlans()" class="btn secondary-btn choose-plan">Choose Plan</button>
                            </div>
                          </div>
                          <div class="cart-btns p-0" v-if="courseDetail && courseDetail.Packages && !courseDetail.Packages.length && !courseDetail.IsFree">
                            <div class="btnHolder">
                              <button class="btn secondary-btn">Coming Soon</button>
                            </div>
                          </div>
                          <div v-if="courseDetail && courseDetail.Summary != undefined && !courseDetail.IsFree" class="course-includes">
                            <div class="title">This Course Includes:</div>
                            <div class="row">
                              <div class="col-sm-6" :class="courseDetail.Summary.VideoCount == 0 ? 'noDataAvail' : ''">
                                <div class="subtitle">
                                  <i class="fal fa-play-circle"></i>
                                  {{ courseDetail.Summary.VideoCount != 0 ? courseDetail.Summary.VideoCount : "" }}
                                  Videos
                                </div>
                              </div>
                              <div class="col-sm-6" :class="courseDetail.Summary.ReadingCount == 0 ? 'noDataAvail' : ''">
                                <div class="subtitle">
                                  <i class="fal fa-book-open"></i>
                                  {{ courseDetail.Summary.ReadingCount != 0 ? courseDetail.Summary.ReadingCount : "" }}
                                  Readings
                                </div>
                              </div>
                              <div class="col-sm-6" :class="courseDetail.Summary.TestCount == 0 ? 'noDataAvail' : ''">
                                <div class="subtitle">
                                  <i class="fal fa-file-signature"></i>
                                  {{ courseDetail.Summary.TestCount != 0 ? courseDetail.Summary.TestCount : "" }}
                                  Quizzes
                                </div>
                              </div>
                              <div class="col-sm-6" :class="courseDetail.Summary.AssignmentCount == 0 ? 'noDataAvail' : ''">
                                <div class="subtitle">
                                  <i class="fal fa-copy"></i>
                                  {{ courseDetail.Summary.AssignmentCount != 0 ? courseDetail.Summary.AssignmentCount : "" }} Assignments
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Single Cart -->
                        <div v-if="courseDetail && courseDetail.Packages && courseDetail.Packages.length == 1">
                          <singlePaymentPlan @choosePlan="showPaymentPlansPopup" @trial="AddToTrial(true)" />
                        </div>
                      </div>
                      <!-- / Single Cart -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container multiPlansCard" id="paymentPlans">
              <span id="targetedPlan"></span>
              <div class="d-none d-lg-block" v-if="courseDetail && courseDetail.Packages && courseDetail.Packages.length === 2">
                <paymentplanmarkup @choosePlan="showPaymentPlansPopup" @trial="AddToTrial(true)" />
              </div>
              <div class="d-none d-lg-block" v-else-if="courseDetail && courseDetail.Packages && courseDetail.Packages.length > 2">
                <multiplePackages @choosePlan="showPaymentPlansPopup" @trial="AddToTrial(true)" />
              </div>
              <!-- Mobile View Start here -->

              <div id="mobileAccordion" class="d-block d-lg-none" v-if="courseDetail && courseDetail.Packages && courseDetail.Packages.length > 1">
                <twoPaymentPlans @choosePlan="showPaymentPlansPopup" @trial="AddToTrial(true)" />
              </div>
              <!-- Mobile View end here -->
            </div>
          </section>
        </template>
      </skeleton-loader>
      <cartConfirmationModal />
      <b-modal centered text id="paymentPlanModal" title="" hide-footer no-close-on-backdrop>
        <div class="mtb30 cart-popup">
          <div class="modal-head modal-head-success payment-model">
            <div v-if="selectedPackage">
              <h3>{{ selectedPackage.Title }} : <span>Payment Plan</span></h3>
              <div class="planstrip" v-for="element in selectedPackage.PaymentPlans" :key="element.SOSPaymentPlanId">
                <label class="radioWrap">
                  <input type="radio" :id="element.SOSPaymentPlanId" v-model="selected" :value="element.SOSPaymentPlanId" @change="changePaymentPlan(null, element)" />
                  <span class="checkmark"></span>
                </label>
                <label class="plandetail" :for="element.SOSPaymentPlanId">
                  <div class="planHeading">{{ element.Title }}</div>
                  <div class="planamount">
                    <span class="font-weight-bold">{{ element.Price.Format }}</span> <span class="strikethrough">{{ element.PriceStrikethrough.Format }}</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="button-bar-confirm">
            <button @click="checkIfPlanAlreadyExist()" class="btn secondary-btn" :disabled="!selected">Choose a plan</button>
          </div>
        </div>
      </b-modal>
    </section>
    <appFooter />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { CartModel } from "../../models/app/cartModel";
import { appConstants } from "../../common/constants";
import { courseDetail as courseDetailStoreKeys, course as courseStoreKeys, checkout as checkoutStoreKeys, cart as cartStoreKeys } from "../../store/keys";
import cartConfirmationModal from "./cartConfirmationModal.vue";
import pageHeader from "../../components/pageHeader.vue";
import footer from "../../layout/landing/appFooter.vue";
import coursePackage from "../../components/packages.vue";
import paymentplanmarkup from "../../components/paymentplanmarkup.vue";
import multiplePackages from "../../components/multiplePackages.vue";
import twoPaymentPlans from "../../components/twoPaymentPlans.vue";
import singlePaymentPlan from "../../components/singlePaymentPlan.vue";
export default {
  components: {
    cartConfirmationModal,
    pageHeader,
    appFooter: footer,
    coursePackage,
    paymentplanmarkup,
    multiplePackages,
    twoPaymentPlans,
    singlePaymentPlan,
  },
  metaInfo() {
    return {
      title: `${this.title} | KIPS VIRTUAL`,
      titleTemplate: "",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
      meta: [
        { name: "description", content: this.courseDetail && this.courseDetail.ShortDescription },
        { property: "og:title", content: this.courseDetail && this.courseDetail.DisplayName },
        {
          property: "og:description",
          content: this.courseDetail && this.courseDetail.ShortDescription,
        },
        { property: "og:image", content: this.courseDetail && this.courseDetail.CourseImage },
      ],
    };
  },
  // props: {
  //   sosId: Number,
  // },
  watch: {
    $route(to, from) {
      if (process.env.VUE_ENV === "client") {
        this.getCourseDetail();
      }
    },
  },
  data() {
    return {
      // caption: routeParams.board.replace("-", " ") - routeParams.group,
      isActive: false,
      title: "",
      // isTrial: false,
      selectedPaymentOption: appConstants.enums.PaymentOption.PaymentPlan,
      routeParams: null,
      isBtnDisable: false,
      isServer: process.env.VUE_ENV,
      // selectedPaymentPlan: {
      //   SOSPaymentPlanId: 0,
      // },
      defaultSelectedPackage: {
        SOSPackageId: 0,
        SOSPackageTitle: "",
        SOSPaymentPlan: {
          id: 0,
          title: "",
          price: 0,
          priceDisplay: "",
          priceStrikethrough: 0,
          priceStrikethroughDisplay: "",
          billingPeriod: {
            frequency: "",
            name: "",
          },
        },
      },
      selected: "",
      selectedPackage: null,
    };
  },
  serverPrefetch() {
    this.reset_CourseDetail();
    return this.getCourseDetail();
  },
  created() {
    this.routeParams = this.$route.params;
  },
  beforeDestroy() {
    this.reset_CourseDetail();
  },
  mounted() {
    window.scrollTo(0, 0);
    // window.addEventListener("scroll", this.handleScroll);
    this.getCourseDetail();
    this.scrollToSelectedNote();
  },
  computed: {
    ...mapGetters(courseDetailStoreKeys.namespace, courseDetailStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    ...mapGetters(cartStoreKeys.namespace, cartStoreKeys.getters),
    sliderArrow() {
      return this.isActive ? "fas fa-angle-down" : "fas fa-angle-up";
    },
    paymentPlans() {
      return this.courseDetail.Packages.find((el) => {
        return el.SOSPaymentPackageId === this.getSelectedSosPaymentPackageId;
      });
    },
  },
  methods: {
    boxHeight() {
      if (this.$refs.stickyBox) {
        return this.$refs.stickyBox.clientHeight + "px";
      }
    },
    ...mapActions(courseDetailStoreKeys.namespace, courseDetailStoreKeys.actions),
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    ...mapActions(cartStoreKeys.namespace, cartStoreKeys.actions),
    ...mapActions(checkoutStoreKeys.namespace, checkoutStoreKeys.actions),
    showPaymentPlansPopup(paymentPackage, paymentOption) {
      this.selectedPaymentOption = paymentOption;
      this.selectedPackage = paymentPackage;
      if (paymentPackage) {
        if (this.selectedPackage.PaymentPlans.length > 1) {
          this.$bvModal.show("paymentPlanModal");
        } else {
          this.changePaymentPlan(this.selectedPackage.PaymentPlans[0].SOSPaymentPlanId, this.selectedPackage.PaymentPlans[0]);
          this.checkIfPlanAlreadyExist();
        }
      } else {
        this.$bvModal.show("paymentPlanModal");
      }
    },
    changePaymentPlan(id, element) {
      this.defaultSelectedPackage = {
        SOSPackageId: this.selectedPackage.SOSPaymentPackageId,
        SOSPackageTitle: this.selectedPackage.Title,
        SOSPaymentPlan: {
          id: id || this.selected,
          title: element.Title,
          price: element.Price.Value,
          priceDisplay: element.Price.Format,
          priceStrikethrough: element.PriceStrikethrough.Value,
          priceStrikethroughDisplay: element.PriceStrikethrough.Format,
          billingPeriod: {
            frequency: element.BillingPeriod.Frequency,
            name: element.BillingPeriod.Period.Name,
          },
        },
      };
    },
    checkIfPlanAlreadyExist() {
      this.$bvModal.hide("paymentPlanModal");
      if (this.isPaymentPlanExist(this.$decryptedRouteParams.sosId)) {
        this.removeFromCart();
      } else {
        this.addToCart();
      }
    },
    removeFromCart() {
      this.remove_FromCart({
        data: this.$decryptedRouteParams.sosId,
        config: {
          loader: {
            show: false,
          },
        },
      })
        .then((response) => {
          // this.$bvModal.hide(`${this.selected}confirmCartModal1`);
          // this.checkout_cart();
          this.addToCart();
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
    selectPaymentPlan(object) {
      this.defaultSelectedPackage = object;
    },
    addToCart() {
      if (this.selectedPaymentOption === appConstants.enums.PaymentOption.PaymentPlan) {
        this.$bvModal.show("confirmCartModal");
      }
      let cartModel = new CartModel();
      cartModel.ProgramCategoryId = this.courseDetail.ProgramCategoryId;
      cartModel.BoardId = this.courseDetail.BoardId;
      cartModel.CourseGroupId = this.courseDetail.CourseGroupId;
      cartModel.SOSId = this.courseDetail.SOSId;
      cartModel.CourseName = this.courseDetail.DisplayName;
      cartModel.CourseGroupName = this.courseDetail.CourseGroupName;
      cartModel.BoardName = this.courseDetail.BoardName;
      cartModel.CourseImage = this.courseDetail.CourseImage;
      cartModel.PaymentOption = this.selectedPaymentOption;
      cartModel.SOSPaymentPlanId = this.defaultSelectedPackage.SOSPaymentPlan.id;
      cartModel.SOSPaymentPackageId = this.defaultSelectedPackage.SOSPackageId;
      cartModel.SOSPaymentPackageTitle = this.defaultSelectedPackage.SOSPackageTitle;
      // cartModel.SOSPaymentPlanId = this.selectedPlan.PaymentPlans.find((el) => el.IsSelectedPlan === true).SOSPaymentPlanId;
      // cartModel.SOSPaymentPackageId = this.selectedPlan.SOSPaymentPackageId;
      cartModel.InstituteId = this.courseDetail.InstituteId;

      if (this.selectedPaymentOption === appConstants.enums.PaymentOption.PaymentPlan) {
        cartModel.Amount = this.defaultSelectedPackage.SOSPaymentPlan.price;
        cartModel.AmountDisplay = this.defaultSelectedPackage.SOSPaymentPlan.priceDisplay;
        cartModel.Title = this.defaultSelectedPackage.SOSPaymentPlan.title;
        cartModel.AmountStrikethrough = this.defaultSelectedPackage.SOSPaymentPlan.priceStrikethrough || this.defaultSelectedPackage.SOSPaymentPlan.price;
        cartModel.AmountStrikethroughDisplay = this.defaultSelectedPackage.SOSPaymentPlan.priceStrikethroughDisplay;
        cartModel.BillingPeriodFrequency = this.defaultSelectedPackage.SOSPaymentPlan.billingPeriod.frequency;
        cartModel.BillingPeriodName = this.defaultSelectedPackage.SOSPaymentPlan.billingPeriod.name;
      } else if (this.selectedPaymentOption === appConstants.enums.PaymentOption.Trial) {
        cartModel.Amount = 0.0;
        cartModel.AmountDisplay = this.toCurrency(0); //"Rs 0.00";
        cartModel.AmountStrikethroughDisplay = this.toCurrency(0); //"Rs 0.00";
      }

      this.add_ToCart({
        data: cartModel,
        config: {
          loader: {
            show: true,
          },
        },
      })
        .then((response) => {
          if (this.selectedPaymentOption === appConstants.enums.PaymentOption.Trial) {
            this.checkoutTrial();
          }
          //this.reset_SelectedCourseInfo();
        })
        .catch((ex) => {
          this.isBtnDisable = false;
          this.$alertError(ex);
        });
    },
    showBottomSlider() {
      this.isActive = !this.isActive;
    },
    // checkoutTrial() {
    //   this.checkout_cart().then((response) => {
    //     // this.$router.push({ name: "signUp" });
    //      window.location.href = `${process.env.VUE_APP_PORTAL_URL}/invoice/checkOut`;
    //   });
    // },
    checkoutTrial() {
      this.check_Out().then(() => {
        this.isBtnDisable = false;
      });
    },
    // AddToTrial(obj) {
    //   this.isBtnDisable = true;
    //   this.isTrial = isTrial;
    //   this.selectedPaymentOption = appConstants.enums.PaymentOption.Trial;
    //   this.showPaymentPlansPopup(obj);
    // },
    goToCart() {
      this.$router.push({ name: "cart" }).catch(() => {});
    },
    // handleScroll() {
    //   if (document.querySelector(".sticky-side-box")) {
    //     let topLimit = document.querySelector(".sticky-side-box").offsetTop;
    //     if (window.pageYOffset - 80 >= topLimit && window.innerWidth > 1199) {
    //       document.querySelector(".sticky-side-box").classList.add("make-it-sticky");
    //     } else if (window.pageYOffset <= 0) {
    //       document.querySelector(".sticky-side-box").classList.remove("make-it-sticky");
    //     }
    //   }

    //   let element = document.querySelector(".details-info");
    //   if (window.scrollY > element.offsetHeight + element.offsetTop - 300) {
    //     document.querySelector(".sticky-side-box").classList.remove("make-it-sticky");
    //     document.querySelector(".course-details-page").classList.add("position-relative");
    //   } else {
    //     document.querySelector(".course-details-page").classList.remove("position-relative");
    //   }
    // },
    setSelectedPaymentPlan(plan) {
      // if (
      //   this.selectedPaymentOption ===
      //   appConstants.enums.PaymentOption.PaymentPlan
      // ) {

      this.update_SelectedPlan({
        data: {
          selectedSOSPaymentPlanId: plan.SOSPaymentPlanId,
        },
      }).catch((ex) => {
        this.$alertError(ex.message);
      });
      //}
    },
    getCourseDetail() {
      return this.get_CourseDetail({
        data: {
          SOSId: this.$decryptedRouteParams.sosId,
          CourseGroupId: this.$decryptedRouteParams.courseGroupId,
        },
        config: {
          loader: {
            show: true,
            id: "courseDetailLoader",
          },
        },
      })
        .then((response) => {
          this.title = this.courseDetail.DisplayName;
          let SosObj = this.selectedSOS(this.courseDetail.SOSId);
          if (SosObj !== undefined) {
            // this.setSelectedPaymentPlan(SosObj);
            this.defaultSelectedPackage.SOSPackageId = SosObj.SOSPaymentPackageId;
            this.defaultSelectedPackage.SOSPackageTitle = SosObj.SOSPaymentPackageTitle;
            this.defaultSelectedPackage.SOSPaymentPlan = {
              id: SosObj.SOSPaymentPlanId,
              title: SosObj.Title,
              price: SosObj.Amount,
              priceDisplay: SosObj.AmountDisplay,
              priceStrikethrough: SosObj.AmountStrikethrough,
              priceStrikethroughDisplay: SosObj.AmountStrikethroughDisplay,
            };
            this.selected = SosObj.SOSPaymentPlanId;
          } else {
            let defaultPackage = this.courseDetail.Packages.filter((el) => {
              return el.PaymentPlans.filter((plan) => {
                return plan.IsSelectedPlan === true;
              });
            })[0];
            let defaultPaymentPlan = defaultPackage.PaymentPlans.find((el) => el.IsSelectedPlan == true);
            this.defaultSelectedPackage.SOSPackageId = defaultPackage.SOSPaymentPackageId;
            this.defaultSelectedPackage.SOSPackageTitle = defaultPackage.Title;
            this.defaultSelectedPackage.SOSPaymentPlan.id = defaultPaymentPlan.SOSPaymentPlanId;
            this.defaultSelectedPackage.SOSPaymentPlan.title = defaultPaymentPlan.Title;
            this.defaultSelectedPackage.SOSPaymentPlan.price = defaultPaymentPlan.Price.Value;
            this.defaultSelectedPackage.SOSPaymentPlan.priceDisplay = defaultPaymentPlan.Price.Format;
            this.defaultSelectedPackage.SOSPaymentPlan.priceStrikethrough = defaultPaymentPlan.PriceStrikethrough.Value;
            this.defaultSelectedPackage.SOSPaymentPlan.priceStrikethroughDisplay = defaultPaymentPlan.PriceStrikethrough.Format;
          }
          // else {
          //   let defaultPackage = this.courseDetail.Packages.filter((el) => {
          //     return el.PaymentPlans.filter((plan) => {
          //       return plan.IsSelectedPlan === true;
          //     });
          //   })[0];
          //   let defaultPaymentPlan = defaultPackage.PaymentPlans.find((el) => el.IsSelectedPlan == true);
          //   this.defaultSelectedPackage.SOSPackageId = defaultPackage.SOSPaymentPackageId;
          //   this.defaultSelectedPackage.SOSPackageTitle = defaultPackage.Title;
          //   this.defaultSelectedPackage.SOSPaymentPlan.id = defaultPaymentPlan.SOSPaymentPlanId;
          //   this.defaultSelectedPackage.SOSPaymentPlan.title = defaultPaymentPlan.Title;
          //   this.defaultSelectedPackage.SOSPaymentPlan.price = defaultPaymentPlan.Price.Value;
          //   this.defaultSelectedPackage.SOSPaymentPlan.priceDisplay = defaultPaymentPlan.Price.Format;
          //   this.defaultSelectedPackage.SOSPaymentPlan.priceStrikethrough = defaultPaymentPlan.PriceStrikethrough.Value;
          //   this.defaultSelectedPackage.SOSPaymentPlan.priceStrikethroughDisplay = defaultPaymentPlan.PriceStrikethrough.Format;
          // }
        })
        .catch((ex) => {
          if (ex.status == 404) {
            this.$router.push({ name: "landingPageNotFound" }).catch(() => {});
          }
          // this.$alertError(ex.message);
        });
    },
    FreeCourse(model) {
      this.isBtnDisable = true;
      this.selectedPaymentOption = appConstants.enums.PaymentOption.Free;
      let cartModel = new CartModel();
      cartModel.ProgramCategoryId = model.ProgramCategoryId;
      cartModel.CourseGroupId = model.CourseGroupId;
      cartModel.BoardId = model.BoardId;
      cartModel.InstituteId = model.InstituteId;
      cartModel.CourseGroupId = model.CourseGroupId;
      cartModel.SOSId = model.SOSId;
      cartModel.CourseName = model.SOSName;
      cartModel.PaymentOption = this.selectedPaymentOption;
      cartModel.SOSPaymentPlanId = null;
      this.reset_Cart();
      this.add_ToCart({
        data: cartModel,
        config: {
          loader: {
            show: true,
          },
        },
      })
        .then((response) => {
          if (model.IsSpecialActivity) {
            this.$router.push({ name: "registration" });
          } else {
            this.check_Out();
          }
          //this.reset_SelectedCourseInfo();
        })
        .catch((ex) => {
          this.isBtnDisable = false;
          this.$alertError(ex);
        });
    },
    scrollToSelectedNote() {
      setTimeout(() => {
        let element = document.querySelectorAll(".highlight");
        if (element && element[0]) {
          element[0].scrollIntoView({
            alignToTop: false,
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);
    },
    scrollToPaymentPlans() {
      let element = document.querySelectorAll("#targetedPlan");
      if (element && element[0]) {
        element[0].scrollIntoView({
          alignToTop: true,
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },
};
</script>
<style scoped>
.red {
  background-color: red;
}

.no-cursor {
  cursor: pointer;
  pointer-events: none;
}

@media (min-width: 1200px) {
  .position-relative {
    position: relative;
  }

  .position-relative .sticky-side-box {
    position: absolute;
    bottom: 70px;
    top: auto;
  }
}

.no-course-image {
  left: 50%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  color: rgba(156, 163, 175, 1);
  font-size: 6rem;
  line-height: 1;
  z-index: 0;
  top: 5rem;
  position: absolute;
}
</style>
