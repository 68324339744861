<template>
  <div class="accordion mb-3 mb-lg-5" role="tablist" v-if="courseDetail && courseDetail.Packages">
    <div class="mb-3 px-1 accorBox"
      :class="[isCourseExist($decryptedRouteParams.sosId, coursePackage.SOSPaymentPackageId) ? 'selected' : '', courseDetail.EnableTrial ? 'freetrail' : '']"
      v-for="(coursePackage, index) in courseDetail.Packages" :key="index">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="accorHead" role="tab">
          <!-- <b-button block v-b-toggle.accordion-61 variant="info">Accordion 1</b-button> -->

          <button v-b-toggle="'accordion-' + coursePackage.SOSPaymentPackageId">
            <div class="cardtd text-center">
              <span class="mainTitle">{{ coursePackage.Title }}</span>
              <h4 v-if="coursePackage.PaymentPlans.length">
                {{ getMinValue(coursePackage).Price.Format ? getMinValue(coursePackage).Price.Format.slice(0, -3) : "" }}
                <s v-if="getMinValue(coursePackage).PriceStrikethrough.Format" class="strikethrough">{{
                  getMinValue(coursePackage).PriceStrikethrough.Format.slice(0, -3) }}</s><small class="d-block">{{
    getMinValue(coursePackage).Title }}</small><small class="d-block"> {{
    getMinValue(coursePackage).BillingPeriod.Frequency }}
                  {{ getMinValue(coursePackage).BillingPeriod.Period.Name }}</small>
              </h4>
              <!-- <button class="btn cart-btn"> Add To Cart </button> -->
              <i class="arrow-down"></i>
            </div>
          </button>
        </b-card-header>

        <b-collapse v-bind:id="`accordion-${coursePackage.SOSPaymentPackageId}`" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text class="position-relative">
              <div class="paymentBox">
                <coursePackage :model="coursePackage" />
                <div class="cart-btns">
                  <button @click="AddToTrial(coursePackage)" class="btn cart-btn" v-if="courseDetail.EnableTrial">Free
                    Trial</button>
                  <div class="btn-loader d-flex justify-content-center mt10" v-if="isServer === 'server'">
                    <span class="skeleton-loader"></span>
                  </div>
                  <div v-else-if="!isCourseExist($decryptedRouteParams.sosId, coursePackage.SOSPaymentPackageId)">
                    <button @click="AddToCart(coursePackage)" class="btn secondary-btn">Add To Cart</button>
                  </div>

                  <div v-else>
                    <button class="btn cart-btn" @click="goToCart">
                      <i class="fa fa-check-circle mr-1"></i>
                      Go to cart
                    </button>
                    <div class="changePlan" v-if="coursePackage.PaymentPlans.length > 1"
                      @click="AddToCart(coursePackage)">Change Plan</div>
                  </div>
                </div>
              </div>
            </b-card-text>

            <b-card-text></b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
  <!-- <div class="container" id="paymentPlans">
    <div class="row mb-3 mb-lg-5" v-if="courseDetail && courseDetail.Packages">
      <div class="col-lg-3 mb-3 px-1" v-for="(coursePackage, index) in courseDetail.Packages" :key="index">
        <div class="paymentBox" :class="[isCourseExist($decryptedRouteParams.sosId, coursePackage.SOSPaymentPackageId) ? 'selected' : '', courseDetail.EnableTrial ? 'freetrail' : '']">
          <coursePackage :model="coursePackage" />
          <div class="cart-btns">
            <div class="btn-loader d-flex justify-content-center mt10" v-if="isServer === 'server'">
              <span class="skeleton-loader"></span>
            </div>
            <div v-else-if="!isCourseExist($decryptedRouteParams.sosId, coursePackage.SOSPaymentPackageId)">
              <button @click="AddToTrial(true)" class="btn cart-btn" v-if="courseDetail.EnableTrial">Free Trial</button>
              <button @click="showPaymentPlans(coursePackage)" class="btn secondary-btn">Add To Cart</button>
            </div>
            <div v-else>
              <div v-if="coursePackage.PaymentPlans.length > 1" @click="showPaymentPlans(coursePackage)">Change Plan</div>
              <button class="btn cart-btn" :disabled="isBtnDisable" @click="goToCart">
                <i class="fa fa-check-circle mr-1"></i>
                Go to cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { courseDetail as courseDetailStoreKeys, course as courseStoreKeys, checkout as checkoutStoreKeys, cart as cartStoreKeys } from "../store/keys";
import coursePackage from "./packages.vue";
import { appConstants } from "../common/constants";
import { BButton } from "bootstrap-vue";
export default {
  components: {
    coursePackage,
  },
  data() {
    return {
      isServer: process.env.VUE_ENV,
    };
  },
  computed: {
    ...mapGetters(courseDetailStoreKeys.namespace, courseDetailStoreKeys.getters),
    ...mapGetters(cartStoreKeys.namespace, cartStoreKeys.getters),
  },
  mounted() { },
  methods: {
    ...mapActions(cartStoreKeys.namespace, cartStoreKeys.actions),
    async AddToCart(obj) {
      this.$emit("choosePlan", obj, appConstants.enums.PaymentOption.PaymentPlan);
    },
    goToCart() {
      this.$router.push({ name: "cart" }).catch(() => { });
    },
    getMinValue(paymentPackage) {
      if (paymentPackage) {
        return paymentPackage.PaymentPlans.reduce((previous, current) => {
          return current.Price.Value < previous.Price.Value ? current : previous;
        });
      }
    },
    AddToTrial(coursePackage) {
      this.$emit("choosePlan", coursePackage, appConstants.enums.PaymentOption.Trial);
    },
  },
};
</script>
